<template>
    <div>
      <div>
        <h1>.</h1>
      </div>
      <div class="terms">
        <h1>쉽차장 가맹 주차장 이용규칙 및 취소/환불규정 동의</h1>
        <select v-model="selectedDate" @change="handleDateChange">
          <option value="2024-00-00">2024년 12월 20일 갱신</option>
        </select>
        <div class="terms-content" v-html="termsByVersion[selectedDate].content"></div>
      </div>
    </div>
  </template>
  
  <script>
 
  
  export default {
    name: 'TermsOfService',
    components: {
     
    },
    data() {
      return {
        selectedDate: '2024-00-00',
        termsByVersion: {
          '2024-00-00': {
            content: `
              <div>
                <h2>이용규칙</h2>
                <ol>
                  <li>결제는 주차하기 시 등록한 결제 수단을 통해 주차 이용 시점부터 주차 종료 시점까지의 사용량만큼 결제됩니다.</li>
                  <li>고객님이 '주차 시작하기' 버튼을 누른 시점부터 가맹 주차공간 자리에 대한 사용이 시작되며 이 시점을 주차 시작 시점으로 봅니다.</li>
                  <li>가맹 주차공간의 운영시간과 요금정책은 가맹 주차공간마다 다를 수 있습니다. 또한, 제공된 정보는 가맹 주차공간의 사정에 따라 변경될 수 있습니다.</li>
                  <li>주차요금은 고객님의 주차 시작 시점부터 주차 종료 시점까지의 사용량만큼 결제됩니다. 
                      이때, 주차 종료 시점은 모바일 애플리케이션에서 해당하는 주차 내역의 ‘주차 종료하기’ 버튼을 누른 시점입니다.</li>
                  <li>가맹 주차공간의 운영시간을 초과해서 사용하는 경우, 운영시간을 초과한 부분에 대해서는 기본 요금의 3배가 부과됩니다. 쉽차장은 이를 방지하기 위해 주차장 운영시간 종료
                  30분 전, 10분 전, 운영종료 시점 총 3번에 걸쳐 Push 알림을 보내며, 운영시간 이후 15분마다 Push 알림을 추가적으로 보내고 있으니 서비스 알림을 꼭 켜주시기 바랍니다.</li>
                  <li>가맹 주차공간의 관리 상태 및 서비스로 인해 발생된 분쟁은 노바링크에서 책임지지 않습니다.</li>
                </ol>
                <h2>취소/환불규정</h2>
                <ol>
                  <li>가맹 주차공간 사용은 '주차 시작하기' 버튼을 누른 시점부터 바로 시작되므로 고객님의 직접 취소가 어렵습니다. 단, 5분 이내에 '주차 종료하기' 버튼을 눌러 주차 종료를 하는 경우에는
                  요금이 과금되지 않습니다.</li>
                  <li>‘주차 시작하기’를 통해 주차를 시작한 경우에는 취소/환불이 불가능합니다.</li>
                </ol>
              </div>
            `,
          },
        },
      };
    },
    methods: {
      handleDateChange() {
        console.log(`Selected date: ${this.selectedDate}`);
      },
    },
  };
  </script>
  
  <style scoped>
  .terms {
    padding: 10rem;
  }
  
  select {
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
  }
  
  .terms-content {
    border: 1px solid #ccc;
    padding: 3rem 4rem;
    background-color: #f9f9f9;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  /* 미디어 쿼리: 태블릿 (1024px 이하) */
@media screen and (max-width: 1024px) {
  .terms {
    padding: 8rem 3rem;
  }

  select {
    font-size: 0.9rem;
    padding: 0.4rem;
  }

  .terms-content {
    padding: 2rem 3rem;
    font-size: 0.85rem;
  }
}

/* 미디어 쿼리: 모바일 (768px 이하) */
@media screen and (max-width: 768px) {
  .terms {
    padding: 9rem 2rem;
  }

  select {
    font-size: 0.8rem;
    padding: 0.3rem;
  }

  .terms-content {
    padding: 1.5rem 2rem;
    font-size: 0.8rem;
    line-height: 1.4;
  }
}

/* 미디어 쿼리: 소형 모바일 (480px 이하) */
@media screen and (max-width: 480px) {
  .terms {
    padding: 0 1rem;
  }

  .terms h1 {
    font-size: 1.3rem;
  }
  
  select {
    font-size: 0.75rem;
    padding: 0.2rem;
  }

  .terms-content {
    padding: 0.75rem ;
    font-size: 0.75rem;
    line-height: 1.2;
  }
  
  ::v-deep(ol) {
    padding-inline-start: 20px;
  }

  ::v-deep(li) {
    margin: 4px 0;
  }
}

  </style>
  
