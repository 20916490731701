<template>
  <div>
    <div>
      <h1>.</h1>
    </div>
    <div class="terms">
      <h1>개인정보처리방침</h1>
      <select v-model="selectedDate" @change="handleDateChange">
        <option value="2024-00-00">2024년 12월 20일 갱신</option>
      </select>
      <div class="terms-content" v-html="termsByVersion[selectedDate]?.content"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PrivacyPage",
  components: {

  },
  data() {
    return {
      selectedDate: "2024-00-00",
      termsByVersion: {
        "2024-00-00": {
          content: `
             <div>
          <p>노바링크(이하 "회사")는 이용자들의 개인정보를 중요시하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인(위치)정보(이하 "개인정보"라 합니다.) 보호법 등 관련법상 개인정보 보호규정을 준수하고, "회원"의 개인정보를 보호하기 위해 지속적으로 노력합니다. 
"회사"는 이 개인정보 취급방침을 통하여 이용자들의 개인정보가 남용 되거나 유출되지 않도록 최선을 다할 것이며, 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있고, 개인정보 보호를 위해 어떠한 조치가 취해지고 있는지 알려드리고자 합니다. 
단, 이 개인정보 취급방침은 정부의 법령 및 지침의 변경, 또는 보다 나은 서비스의 제공을 위하여 그 내용이 변경 될 수 있으니, 이용자들께서는 사이트 방문 시 수시로 그 내용을 확인하여 주시기 바라며, 
"회사"는 개인정보 취급방침을 개정하는 경우 웹사이트 공지사항 또는 개별공지를 통하여 공지할 것입니다.<br/>
단, "회사"의 공식사이트 이외의 웹에서 링크된 사이트에서는 "회사"의 개인정보 취급방침 정책이 적용되지 않으며 "회사"는 "이용자"의 귀책사유로 인해 노출된 정보에 대해서는 일체의 책임을 지지 않습니다.
</p>
          <h2>제 1조 수집하는 개인정보 처리 항목 및 목적</h2>
        <p>회사는 회원가입·유지·탈퇴 의사 확인, 본인인증 및 식별, 고객응대, 서비스 부정이용 방지, 통계학적 분석 및 기타 각종 서비스의 제공을 위해 이용자의 사전동의를 받아 개인정보를 수집하고 있습니다. 
          개인정보는 다음의 수집·이용목적 이외의 용도로는 활용되지 않으며, 수집·이용목적이 변경되는 경우에는 개인정보보호법에 따라 별도의 동의를 받는 등 필요한 조치를 이행합니다.</p>
        <ol>
          <li>개인정보 수집방법
            <ol className='nested'>
              <li>· 회사는 회원가입 화면 또는 이용자가 회사가 제공하는 각 서비스 이용시 표출되는 화면에서 ‘개인정보의 수집·이용에 대한 동의’에 ‘동의한다(동의)’ 또는 ‘동의하지 않는다(미동의)’와 같은 클릭할 수 있는 버튼을 마련하여, 회원이 ‘동의한다(동의)’를 클릭하면 개인정보 수집에 동의한 것으로 봅니다. 
                회사는 필요한 경우 회사가 제공하는 개별 서비스에 관하여 별도의 개인정보 수집·이용에 관한 동의를 얻을 수 있습니다.</li>
              <li>· 회사는 홈페이지, 서면양식, 앱(모바일 애플리케이션), 전화, 팩스, 문의 게시판, 이벤트응모, 배송요청, 외부 제휴기업·협력사·단체로부터의 제공 등의 방법으로 개인정보를 수집할 수 있습니다.</li>
            </ol>
          </li>
          <li>회원가입 과정에서 수집하는 정보
            <ol className='nested'>
              <li>· 수집항목: 이름, 연락처(휴대전화번호, 자택 및 직장 전화번호), 생년월일, 아이디, 비밀번호, 기타 서비스의 설정·유지·이행·관리·개선 등과 관련하여 회원이 제공한 정보</li>
              <li>· 이용목적: 회원제 서비스 제공, 마케팅 활동, 업데이트 및 고지(문의)사항 안내, 만 14세 미만 아동의 이용 제한, 인구통계학적 특성과 이용자의 관심, 기호, 성향의 추정을 통한 맞춤형 주차장 추천</li>
            </ol>
          </li>
          <li>주차장 제보하기, 문의하기 과정에서 수집하는 정보
            <ol className='nested'>
              <li>· 수집항목: 이름, 휴대전화번호, 생년월일</li>
              <li>· 이용목적: 반복되는 민원으로 인한 업무 방해 방지, 적립금 제공</li>
            </ol>
          </li>
          <li>결제 수단 등록 시
            <ol className='nested'>
              <li>· 수집항목: 카드사, 카드별명</li>
              <li>· 이용목적: 서비스 이용 후 등록된 결제 수단으로 자동결제 및 사용자가 등록한 결제수단 관리 목적</li>
            </ol>
          </li>
          <li>주차하기 서비스 이용 시
            <ol className='nested'>
              <li>· 수집항목: 차량 번호를 포함한 차량의 외부 사진</li>
              <li>· 이용목적: 차량 식별 및 관리, 부정 주차 방지 및 고객 문의 대응응</li>
            </ol>
          </li>
          <li>그 외, 서비스 이용과정에서 자동적으로 생성되어 수집되는 개인정보
            <ol className='nested'>
              <li>· 수집방법: 자동생성</li>
              <li>· 수집항목: 접속 IP정보, 접속 로그, 쿠키, 서비스 이용기록, 기기고유번호 등</li>
              <li>· 이용목적: 본인식별, 부정이용 방지, 서비스 향상을 위한 이용실적 분석</li>
            </ol>
          </li>
          <li>그 외, 마케팅 이벤트 및 행사에 참여하는 과정에서 이용자 선택에 의해 이름, 생년월일, 성별, 이메일 주소, 블로그 주소, 학교, 회사, 기타 경품 제공 및 배송을 위해 필요한 정보 등이 수집 될 수 있습니다. 
     이 경우, 수집항목 및 이용목적을 별도로 회원에게 고지하여 동의를 구합니다.</li>
          <li>이용자는 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동의 거부 시 회원가입이 제한되어 서비스 이용이 불가합니다.</li>
        </ol>
        <h2>제 2조 개인정보의 제 3자 제공</h2>
        <p>회사는 이용자의 개인정보를 개인정보 수집·이용 목적에서 명시한 범위 내에서 사용하며, 이용자의 사전 동의 없이 개인정보 수집·이용 목적 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다.
<br/>다만, 양질의 서비스 제공을 위해 이용자의 개인정보를 협력 업체와 공유할 필요가 있는 경우에는 최소한의 범위 내에서 제 3자에게 정보를 제공할 수 있으며 이 때는 제공 받는 자, 제공목적, 제공정보 항목, 이용 및 보유기간 등을 회원에게 고지하여 동의를 구합니다.
<br/>하지만 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우는 예외로 합니다. 이용자는 본 조에 따른 개인 정보의 제공에 대한 동의를 거부할 수 있으나, 이를 거부할 경우에는 로그인이 필요한 일부 서비스 이용이 제한됩니다.</p>
        <h2>제 3조 개인정보처리의 위탁</h2>
        <p>회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리 업무를 위탁하고 있습니다.</p>
        <ol>
          <li>(주)드림시큐리티
            <ol className='nested'>
              <li>· 위탁업무 내용: 본인인증</li>
              <li>· 수집항목: 이름, 생년월일, 휴대폰번호, 내/외국인 구분, 이동통신사, 연계정보(CI), 중복가입확인정보(DI)</li>
              <li>· 보존기간: 본인인증 후 즉시 파기</li>
            </ol>
          </li>
          <li>(주)나이스페이먼츠
            <ol className='nested'>
              <li>· 위탁업무 내용: 전자결제 수단 제공</li>
              <li>· 수집항목: 결제정보(”이용자”가 상점의 상품 및 서비스를 구매하기 위하여 “회사”가 제공하는 ‘서비스’를 통해 제시한 각 결제수단 별 제반 정보를 의미하며 신용카드 번호, 신용카드 유효기간, 성명, 계좌번호, 휴대폰번호, 유선전화번호)</li>
              <li>· 보존기간: 계약 해지 5년 이후 파기</li>
            </ol>
          </li>
        </ol>
        <h2>제 4조 개인정보 수집 · 이용 · 제공사실 확인자료의 보유근거 및 보유기간</h2>
        <p>회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보 수집시 동의 받은 개인정보 보유·이용기간 내에서 이용자의 개인정보를 보유 및 이용합니다.
           회원탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집/이용목적을 달성하고나 보유/이용기간이 종료한 경우, 사업폐지 등의 사유발생 시 당해 개인정보를 지체없이 파기합니다.
단, 서비스의 부정한 이용으로 인한 분쟁을 방지하기 위해 내부방침 또는 관계 법령의 규정을 따르거나, 관련 법령의 규정에 따라 보존할 필요가 있을 경우 일정 기간 보유합니다. 보관하는 개인정보 및 해당 법령은 아래와 같습니다.</p>
        <ol>
          <li>이름, 휴대전화번호, 아이디(이메일), 비밀번호
            <ol className='nested'>
              <li>· 보존근거: 회원가입 남용 등 서비스 부정이용 방지를 위한 회사 내부정책</li>
              <li>· 보존기간: 회원 탈퇴 후 1년</li>
            </ol>
          </li>
          <li>로그기록, IP 등
            <ol className='nested'>
                <li>· 근거법령: 통신비밀보호법</li>
                <li>· 보존기간: 3개월</li>
            </ol>
          </li>
          <li>표시/광고에 관한 기록
            <ol className='nested'>
                <li>· 근거법령: 전자상거래 등에서의 소비자 보호에 관한 법률</li>
                <li>· 보존기간: 6개월</li>
            </ol>
          </li>
          <li>소비자의 불만 또는 분쟁처리에 관한 기록
            <ol className='nested'>
                <li>· 근거법령: 전자상거래 등에서의 소비자 보호에 관한 법률</li>
                <li>· 보존기간: 3년</li>
            </ol>
          </li>
        </ol>
        <p>단, 관련 법령의 규정에 따라 보존할 필요가 있을 때에는 해당 법령에서 정한 바에 따라 보존합니다.</p>
        <h2>제 4조 개인정보 파기절차 및 방법</h2>
        <p>회사는 개인정보의 수집 및 이용목적이 달성되고, 보유 및 이용기간에 명시한 기간이 경과한 경우, 개인정보를 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후 파기됩니다. 
동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
종이에 출력된 개인정보 및 접수된 서류는 분쇄하거나 소각을 통하여, 전자적 파일 형태로 저장된 개인정보는 복구할 수 없는 기술적 방법을 사용하여 삭제합니다.
<br/><br/>
회사는 휴업 또는 폐업 시에 다음 각 호의 구분에 따라 개인정보 및 위치정보 수집사실 확인자료를 파기합니다.
<br/><br/>
휴업승인: 개인정보 (사업의 일부를 휴업하는 경우에는 휴업하는 사업의 개인정보로 한정)
<br/><br/>
폐업승인: 개인정보 (사업의 일부를 폐업하는 경우에는 폐업하는 사업의 개인정보로 한정)
</p>
        <h2>제 5조 이용자 및 법정대리인의 개인정보데 대한 권리와 그 행사방법</h2>
        <p>만 14세 미만 아동의 개인정보는 수집하지 않습니다. 다만, 법정대리인의 요구가 있는 경우 법령 상의 권리를 보장합니다. (아동의 개인정보에 대한 열람, 정정·삭제, 개인정보처리정지요구권)</p>
        <h2>제 6조 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h2>
        <p>회사는 회사의 사이트(www.shipchajang.com) 방문자에게 적정한 서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 
쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내 하드디스크에 저장되기도 합니다. 
회사는 이용자('사용자' 접속자)들이 방문한 쉽차장의 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.<br></br>
이용자는 쿠키 설정에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저의 옵션을 설정함으로써, 쿠키가 저장될 때마다 확인하거나 모든 쿠키의 허용 또는 모든 쿠키의 저장을 거부할 수도 있습니다. 
다만 쿠키의 저장을 거부한 경우에는 로그인이 필요한 일부 서비스는 이용이 제한될 수 있습니다.
<br></br><br/>
* 쿠키 설치 허용 여부 설정방법(인터넷 익스플로어의 경우에 적용되며, 기타 브라우저는 해당 브라우저의 설정방법에 따름): 웹 브라우자 상단의 도구 〉 인터넷 옵션 〉 개인정보</p>
        <h2>제 7조 개인정보의 기술적/관리적 보호대책</h2>
        <p>회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안정성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구 하고 있습니다.</p>
        <ol>
          <li>기술적 보호대책
            <ol className='nested'>
              <li>휴대전화번호와 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 이용자의 개인정보를 암호화된 통신구간을 이용하여 전송하고,
     비밀번호 등 중요정보는 암호화하여 보관하고 있습니다. 회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
     개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있습니다.
     회사는 개인정보를 취급하는 직원을 최소화하며, 개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다.</li>
            </ol>
          </li>
          <li>관리적 보호대책
            <ol className='nested'>
              <li>회사는 개인정보관련 취급 직원을 담당자에 한정시키고 이를 위한 별도의 비밀번호를 부여하고, 정기적으로 갱신하고 있습니다.
     회사는 주기적으로 담당자들을 교육하고 있으며, 이용자의 개인정보 취급·관리의 보안을 강조하고 있습니다.
     회사는 사내 개인정보보호 관련부서 등을 통해서 회사의 개인정보취급방침 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경웅 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.</li>
            </ol>
          </li>
        </ol>
        <h2>제 8조 기타 이용자의 준수사항</h2>
        <p>이용자는 자신의 개인정보를 보호할 의무가 있으며, 회사의 귀책사유 없이 이용자 본인의 부주의나 브라우저 취약점, 관계법령에 의한 보안조치로 차단할 수 없는 방법이나 기술을 사용한 해킹 등 회사가 상당한 주의에도 불구하고 통제할 수 없는 인터넷상의 문제 등으로
개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.<br/>
이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다. 타인의 개인정보를 도용하여 사이트 이용 시 회원자격 상실과 함께 주민등록법에 의거하여 처벌될 수 있습니다.
이용자는 아이디, 비밀번호 등에 대해 보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여할 수 없습니다.<br/>
이용자는 「정보 통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보보호법」, 「주민등록법」 등 기타 개인정보에 관한 법률을 준수하여야 합니다.</p>
        <h2>제 9조 개인정보취급방침의 적용범위</h2>
        <p>회사는 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 이 경우 링크된 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 개인정보처리방침이 적용되지 않으며, 
          회사는 해당 웹사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대하여 책임질 수 없으며 보증할 수 없습니다. 
          회사가 제공하는 링크를 클릭하여 다른 웹사이트로 연결되는 경우 해당 웹사이트의 개인정보처리방침은 회사와 무관하므로, 해당 웹사이트 제공자의 정책을 검토하시기 바랍니다.
        </p>
        <h2>제 10조 개인정보 관리책임자 및 담당자의 연락처</h2>
        <p>이용자는 회사의 서비스를 이용하는 과정에서 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고할 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 답변을 드릴 것입니다.</p>
        <ol>
          <li>개인정보 관리책임자
            <ol className='nested'>
              <li>· 이름: 강진욱</li>
              <li>· 직책: 이사</li>
              <li>· 메일: red1659@shipchajang.com</li>
            </ol>
          </li>
          <li>개인정보 보호 담당부서
            <ol className='nested'>
              <li>· 부서명: 사업실</li>
              <li>· 메일: red1659@shipchajang.com</li>
            </ol>
          </li><br/>
          <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/><br/>
          개인정보침해신고센터 (http://www.118.or.kr , 118)<br/>
          정보보호마크인증위원회 (http://www.eprivacy.or.kr , 02-580-0553~4)<br/>
          대검찰청 첨단범죄수사과 (http:www.spo.go.kr , 02-3480-2000)<br/>
          경찰청 사이버테러대응센터 (http://www.ctrc.go.kr , 02-392-0330)</p>
        </ol>
        <h2>제 11조 개인정보 취급(처리)방침의 변경</h2>
        <p>회사는 개인정보 취급방침의 변경(내용 추가, 삭제 및 수정)이 있을 경우 7일 전 웹사이트 또는 어플리케이션 내 공지사항을 통해 고지하고 있습니다. 또한 변경된 내용을 고객이 쉽게 확인할 수 있도록 변경 전 · 후를 비교하여 공개하고 있습니다.</p>
        <h2>제 12조 고지의 의무</h2>
        <p>현 개인정보 취급방침의 내용 추가, 삭제 및 수정 등의 변경이 있을 시에는 시행일의 7일 전부터 홈페이지와 어플리케이션 공지사항을 통해 고지할 것입니다.
        단, 이용자 권리의 중대한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
        </p>
        <h2>제 13조 보호의무자의 권리·의무 및 그 행사방법</h2>
        <h2>제 14조 개인정보의 제3자 제공 및 통보에 관한 사항</h2>
        <ol>
          <li>회사는 이용자의 사전 동의 없이 개인정보를 제3자에게 제공하지 않습니다. 단, 사전에 개인정보를 목적 외의 용도로 제공하는 것에 대한 동의를 받은 경우, 
            통계처리·학술연구·시장조사 등을 위하여 필요한 경우로서 특정한 개인을 식별할 수 없는 형태로 개인정보를 제공하는 경우, 다른 법령에 특별한 규정이 있는 경우, 
            정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 
            신체, 재산의 이익을 위하여 필요하다고 인정되는 경우, 회사가 법령상 의무를 준수하기 위하여 불가피한 경우는 예외로 합니다.
          </li>
          <li>이용자가 지정한 제3자에게 개인정보를 제공하는 경우 매회 개인정보주체에게 제공받는 자, 제공 일시 및 제공 목적을 즉시 통보합니다.</li>
          <li>이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공에 직접 동의한 경우, 이용자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우에 한하여 개인정보를 제공합니다.</li>
        </ol>
        <h2>부칙</h2>
        <p>본 약관은 2024년 12월 20일부터 시행됩니다.</p>
        </div>
            `,
        },
      },
    };
  },
  methods: {
    handleDateChange() {

    },
  },
};
</script>

<style scoped>
.terms {
  padding: 10rem;
}

select {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
}

.terms-content {
  border: 1px solid #ccc;
  padding: 3rem 4rem;
  background-color: #f9f9f9;
  font-size: 0.9rem;
  line-height: 1.5;
}
/* 미디어 쿼리: 태블릿 (1024px 이하) */
@media screen and (max-width: 1024px) {
  .terms {
    padding: 8rem 3rem;
  }

  select {
    font-size: 0.9rem;
    padding: 0.4rem;
  }

  .terms-content {
    padding: 2rem 3rem;
    font-size: 0.85rem;
  }
}

/* 미디어 쿼리: 모바일 (768px 이하) */
@media screen and (max-width: 768px) {
  .terms {
    padding: 9rem 2rem;
  }

  select {
    font-size: 0.8rem;
    padding: 0.3rem;
  }

  .terms-content {
    padding: 1.5rem 2rem;
    font-size: 0.8rem;
    line-height: 1.4;
  }
}

/* 미디어 쿼리: 소형 모바일 (480px 이하) */
@media screen and (max-width: 480px) {
  .terms {
    padding: 0 1rem;
  }

  .terms h1 {
    font-size: 1.3rem;
  }
    
  select {
    font-size: 0.75rem;
    padding: 0.2rem;
  }

  .terms-content {
    padding: 0.75rem ;
    font-size: 0.75rem;
    line-height: 1.2;
  }
  ::v-deep(ol) {
    padding-inline-start: 20px;
  }

  ::v-deep(li) {
    margin: 4px 0;
  }
}

</style>
