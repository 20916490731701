<template>
    <div>
      <div>
        <h1>.</h1>
      </div>
      <div class="terms">
        <h1>개인정보 위탁 처리 동의</h1>
        <select v-model="selectedDate">
          <option value="2024-00-00">2024년 12월 20일 갱신</option>
        </select>
        <div class="terms-content" v-html="termsByVersion[selectedDate].content"></div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "PrivacyConsentPage",
    data() {
      return {
        selectedDate: "2024-00-00",
        termsByVersion: {
          "2024-00-00": {
            content: `
              <div>
                <p>노바링크는 서비스를 구매하고자 할 경우 원활한 거래 이행을 위하여 아래와 같이 개인정보를 위탁하고 있습니다.</p>
                <ol>
                  <li>[필수] 수탁업체: ㈜나이스페이먼츠<br>위탁업무 내용: 전자결제 대행</li>
                  <li>[필수] 수탁업체: ㈜드림시큐리티<br>위탁업무 내용: 본인인증</li>
                </ol>
              </div>
            `,
          },
        },
      };
    },
  };
  </script>
  
  <style scoped>
  .terms {
    padding: 10rem;
  }
  
  select {
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
  }

  .terms-content {
    border: 1px solid #ccc;
    padding: 3rem 4rem;
    background-color: #f9f9f9;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  /* 미디어 쿼리: 소형 모바일 (480px 이하) */
  @media screen and (max-width: 480px) {
    .terms {
      padding: 0 1rem;
    }

    .terms h1 {
      font-size: 1.3rem;
    }

    select {
      font-size: 0.75rem;
      padding: 0.2rem;
    }

    ::v-deep(ol) {
      padding-inline-start: 20px;
    }

    ::v-deep(li) {
      margin: 2px 0;
    }

    .terms-content {
      padding: 0.75rem ;
      font-size: 0.75rem;
      line-height: 1.2;
    }
  }
  </style>
  
