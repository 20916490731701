<template>
    <div>
      <div>
        <h1>.</h1>
      </div>
      <div class="terms">
        <h1>전자결제대행 이용 동의 (나이스페이먼츠)</h1>
        <select v-model="selectedDate">
          <option value="2024-00-00">2020년 08월 03일 갱신</option>
        </select>
        <div class="terms-content" v-html="termsByVersion[selectedDate].content"></div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "PGAgree",
    components: {
    },
    data() {
      return {
        selectedDate: "2024-00-00",
        termsByVersion: {
          "2024-00-00": {
            content: `
              <div>
                <h2>제 1조 (목적)</h2>
                <p>이 약관은 NICE페이먼츠 주식회사(이하 '회사'라 합니다)가 제공하는 
                전자지급결제대행서비스 및 결제대금예치서비스를 이용자가 이용함에 있어 회사와 이용자 사이의 전자금융거래에 관한 기본적인 사항을 정함을 목적으로 합니다.</p>
                
                <h2>제 2조 (용어의 정의)</h2>
                <p>이 약관에서 정하는 용어의 정의는 다음과 같습니다.</p>
                <ol>
                  <li>'전자금융거래'라 함은 회사가 전자적 장치를 통하여 전자지급결제대행서비스 및 결제대금예치서비스를 제공하고, 
                  이용자가 회사의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.</li>
                  <li>'전자지급결제대행서비스'라 함은 전자적 방법으로 재화의 구입 또는 용역의 이용에 있어서 지급결제정보를 송신하거나 수신하는 것 
                  또는 그 대가의 정산을 대행하거나 매개하는 서비스를 말합니다.</li>
                  <li>'결제대금예치서비스'라 함은 이용자가 재화의 구입 또는 용역의 이용에 있어서 그 대가(이하 '결제대금'이라 한다)의 전부 또는 
                  일부를 재화 또는 용역(이하 '재화 등'이라 합니다)을 공급받기 전에 미리 지급하는 경우, 회사가 이용자의 물품수령 또는 서비스 이용 확인 시점까지 결제대금을 예치하는 서비스를 말합니다.</li>
                  <li>'이용자'라 함은 이 약관에 동의하고 회사가 제공하는 전자금융거래 서비스를 이용하는 자를 말합니다.</li>
                </ol>
                
                <h2>제 3조 (약관의 명시 및 변경)</h2>
                <ol>
                  <li>회사는 이용자가 전자금융거래 서비스를 이용하기 전에 이 약관을 게시하고 이용자가 이 약관의 중요한 내용을 확인할 수 있도록 합니다.</li>
                  <li>회사는 이용자의 요청이 있는 경우 전자문서의 전송방식에 의하여 본 약관의 사본을 이용자에게 교부합니다.</li>
                  <li>회사가 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는 약관을 회사가 제공하는 전자금융거래 서비스 이용 초기화면 및 회사의 홈페이지에 게시함으로써 이용자에게 공지합니다.</li>
                </ol>
                
                <h2>부칙</h2>
                <p>최초 시행일자 : 2016년 7월 17일</p>
                <p>변경 공고일자 : 2020년 7월 1일</p>
                <p>변경 시행일자 : 2020년 8월 3일</p>
              </div>
            `,
          },
        },
      };
    },
  };
  </script>
  
  <style scoped>
  .terms {
    padding: 10rem;
  }
  
  select {
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
  }

  .terms-content {
    border: 1px solid #ccc;
    padding: 3rem 4rem;
    background-color: #f9f9f9;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  /* 미디어 쿼리: 소형 모바일 (480px 이하) */
  @media screen and (max-width: 480px) {
    .terms {
      padding: 0 1rem;
    }

    .terms h1 {
      font-size: 1.3rem;
    }

    select {
      font-size: 0.75rem;
      padding: 0.2rem;
    }

    ::v-deep(ol) {
      padding-inline-start: 20px;
    }

    ::v-deep(li) {
      margin: 2px 0;
    }

    .terms-content {
      padding: 0.75rem ;
      font-size: 0.75rem;
      line-height: 1.2;
    }
  }
  </style>
  